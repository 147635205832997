import { FaNutritionix } from 'react-icons/fa6';
import { BiSolidWine } from 'react-icons/bi';
import { MdFoodBank } from 'react-icons/md';

export const features = [
  {
    id: 1,
    name: 'Recipe Creation',
    description: 'uncover endless recipe possibilities',
    href: '/genrecipe',
    icon: MdFoodBank,
  },
  {
    id: 2,
    name: 'Master Chef',
    description: 'achieve your macronutrient goals',
    href: '/genrecipe',
    icon: FaNutritionix,
  },
  {
    id: 3,
    name: 'Mixology',
    description: 'the perfect drink for any occasion',
    href: '/gencocktail',
    icon: BiSolidWine,
  },
];

export const callToActions = [
  {
    name: 'See Demo',
    href: '#',
    icon: '',
  },
  {
    name: 'Contact Support',
    href: '#',
    icon: '',
  },
];
