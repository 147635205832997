import React from 'react';
import Link from 'next/link';
import {
  FaLinkedin, FaFacebookSquare,
} from 'react-icons/fa';
// import { FaXTwitter } from 'react-icons/fa6';
// import { RiShareBoxLine } from "react-icons/ri";

const SocialMediaIcons = () => (
  <div className="flex justify-center md:justify-start my-10 gap-7">
    <Link
      className="hover:opacity-50 transition duration-500"
      href="https://www.linkedin.com/company/cookgpt-io"
      target="_blank"
      rel="noreferrer"
      aria-label="LinkedIn profile"
    >
      <FaLinkedin size={32} />
    </Link>
    <Link
      className="hover:opacity-50 transition duration-500"
      href="https://www.facebook.com/profile.php?id=61567628430677"
      target="_blank"
      rel="noreferrer"
      aria-label="Github profile"
    >
      <FaFacebookSquare size={32} />
    </Link>
    {/* <Link
        className="hover:opacity-50 transition duration-500"
        href="https://twitter.com/Binod_ironLad"
        target="_blank"
        rel="noreferrer"
        aria-label="Twitter profile"
      >
        <FaXTwitter size={32} />
      </Link> */}

  </div>
);

export default SocialMediaIcons;
