/* eslint-disable @typescript-eslint/no-unused-vars */
// components/CookieConsent.js

'use client';

import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent = () => {
  const [consentLevel, setConsentLevel] = useState<null | 'all' | 'none'>(null);

  const handleAcceptAll = () => {
    setConsentLevel('all');
  };

  const handleRejectAll = () => {
    setConsentLevel('none');
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      cookieName="myAwesomeCookieName"
      style={{
        background: '#2B373B', color: '#fff', display: 'flex', alignItems: 'center', paddingRight: '100px',
      }}
      buttonStyle={{
        color: '#fff',
        backgroundColor: '#4CAF50',
        marginRight: '10px',
      }}
      expires={150}
      onAccept={handleAcceptAll}
      onDecline={handleRejectAll}
      hideOnAccept
      enableDeclineButton
      declineButtonText="Reject All"
    >
      <p style={{ fontSize: '14px', marginBottom: '10px' }}>
        This website uses cookies to enhance your experience. You can choose to
        accept all cookies or reject non-essential cookies.

      </p>

    </CookieConsent>
  );
};

export default CookieConsentComponent;
