'use client';

import React, { useEffect, useState } from 'react';
import {
  useAuth,
  SignedIn,
  SignedOut,
  UserButton,
} from '@clerk/nextjs';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { HiMiniChevronDown } from 'react-icons/hi2';
import { FaBars } from 'react-icons/fa6';
import { features } from '@/lib/nav-menu-items';
import Link from 'next/link';
import { TbLayoutDashboardFilled } from 'react-icons/tb';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { Button } from '../ui/button';
import MobileNavSidebar from './mobile-nav-sidebar';

const Navbar = () => {
  const { isSignedIn } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isFeatureOpen, setIsFeatureOpen] = useState(false);
  const pathname = usePathname();
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const aBeforeClass = "before:content-[''] before:absolute before:left-0 before:bottom-[-5px] before:w-0 before:h-[2px] before:bg-green before:transition-all before:duration-300 hover:before:w-full";
  const aClasses = 'inline-block transition-all duration-500  relative text-base leading-6 text-gray-200 hover:text-white';
  const handleMouseOpen = () => {
    setIsFeatureOpen(true);
  };
  const handleMouseLeave = () => {
    setIsFeatureOpen(false);
  };
  return (
    <nav
      className="z-40 bgc-color px-6 lg:px-16 fixed w-full top-0"
      aria-label="Global"
    >
      <MobileNavSidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />

      <div className="w-full font-lato text-base h-[70px] flex items-center justify-between">
        <div className="flex lg:hidden">
          <div className="lg:hidden -ml-2">
            <Link href="/">
              <Image
                src="/cookgpt_logo_mobile.webp"
                alt="cookgpt_logo"
                className="h-[35px] min-w-[40px]"
                width={30}
                height={35}
              />
            </Link>
          </div>
          <button
            type="button"
            aria-label="clickme"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-200"
            onClick={() => setMobileOpen(true)}
          >
            <FaBars className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1">
          <Link href="/">
            <Image
              src="/cookgpt_logo.png"
              alt="cookgpt_logo"
              className="h-[35px] min-w-[140px] -ml-2"
              width={140}
              height={35}
            />
          </Link>
        </div>

        <Popover className="relative hidden lg:flex lg:gap-x-12 items-center">
          <div
            onMouseEnter={handleMouseOpen}
            onMouseLeave={handleMouseLeave}
          >
            <PopoverButton
              className={`${aBeforeClass} ${aClasses} flex items-center ml-2 inline-block outline-0 text-base font-lato leading-6 text-gray-200`}
              // eslint-disable-next-line max-len
              onMouseEnter={(event: { currentTarget: { click: () => void; }; }) => event.currentTarget.click()}
            >
              <div
                className="flex items-center justify-center"
              >
                <span>Features</span>
                <span>
                  <HiMiniChevronDown
                    className={`h-8 w-7 text-gray-200 transition-transform duration-300 ${
                      isFeatureOpen ? 'rotate-180' : ''
                    }`}
                  />
                </span>
              </div>
            </PopoverButton>
            <Transition
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <PopoverPanel
                onMouseLeave={(event: { currentTarget: { parentElement: any; }; }) => {
                  const { parentElement } = event.currentTarget;
                  if (parentElement) {
                    const button = parentElement.querySelector('[aria-expanded="true"]');
                    if (button) {
                      (button as HTMLButtonElement).click();
                    }
                  }
                }}
                className="absolute font-lato bg-white -left-8 top-full z-10 mt-3 max-w-md overflow-hidden rounded-3xl shadow-lg ring-1 ring-gray-900/5"
              >
                <div className="p-4">
                  {features.map((item) => (
                    <div
                      key={item.id}
                      className="group relative flex gap-x-2 items-center justify-center rounded-lg p-4 text-md hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-200">
                        <item.icon
                          className="h-8 w-8 text-[#013894] group-hover:text-deep-blue"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          href={isSignedIn ? item.href : '/sign-up'}
                          className="block font-semibold text-[#013894]"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-[#013894] text-sm">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Transition>
          </div>
          <Link href={pathname === '/' ? '#price_plan' : '/pricing'} className={`${aBeforeClass} ${aClasses}`}>
            Pricing
          </Link>
          <Link href="/blog" className={`${aBeforeClass} ${aClasses}`}>
            Blog
          </Link>
          <Link href="/faq" className={`${aBeforeClass} ${aClasses}`}>
            FAQ
          </Link>
        </Popover>
        <div className="flex flex-1 justify-end items-center gap-x-2">
          <div className="flex items-center gap-x-4">
            <SignedOut>
              <Link href={isSignedIn ? '/dashboard' : '/sign-up'}>
                <Button className="btn text-base">Get started</Button>
              </Link>
            </SignedOut>
            <SignedIn>
              <Link
                href="/dashboard"
                className="flex items-center gap-1 text-gray-200 hover:text-gray-100"
              >
                <TbLayoutDashboardFilled className="h-5 w-5" />
                {' '}
                Dashboard
              </Link>
              <UserButton />
            </SignedIn>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
