'use client';

import React from 'react';
import Link from 'next/link';
import { MdOutlineArrowRight } from 'react-icons/md';
import { useAuth } from '@clerk/nextjs';
import { usePathname } from 'next/navigation';
import SocialMediaIcons from '../social-media';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { isSignedIn } = useAuth();
  const pathname = usePathname();

  return (
    <div className="text-white w-full px-6 mx-auto text-center lg:text-left bg-light-blue">
      <div className="py-5">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-6">
          {/* About Section */}
          <div className="lg:col-span-5">
            <h1 className="text-white mb-4 text-lg">About</h1>
            <p className="text-white/90 lg:w-[80%]">
              CookGPT is your ultimate culinary companion, leveraging the power of AI to
              transform your cooking experience. Our platform offers personalized recipe
              recommendations, step-by-step cooking guides, and innovative tools to help you
              create delicious meals with ease. Join us on this flavorful journey and elevate
              your cooking game with CookGPT!
            </p>
            <SocialMediaIcons />
            <div className="-mt-4 text-white/70">
              <p className="text-sm">support@cookgpt.io</p>
              <p className="text-sm">+351-920039576</p>
              <p className="text-sm">2620-064 Olival Basto, Lisboa, Portugal</p>
            </div>
          </div>

          {/* Features Section */}
          <div className="lg:col-span-2">
            <h1 className="text-white mb-4 text-lg">Features</h1>
            <ul className="flex flex-col gap-2 text-[15px]">
              <li>
                <Link href={isSignedIn ? '/genrecipe' : '/sign-up'} className="text-white/90 hover:text-white hover:underline cursor-pointer w-full block">
                  Recipe Creation
                </Link>
              </li>
              <li>
                <Link href={isSignedIn ? '/genrecipe' : '/sign-up'} className="text-white/90 hover:text-white hover:underline cursor-pointer w-full block">
                  Master Chef
                </Link>
              </li>
              <li>
                <Link href={isSignedIn ? '/gencocktail' : '/sign-up'} className="text-white/90 hover:text-white hover:underline cursor-pointer w-full block">
                  Mixology
                </Link>
              </li>
            </ul>
          </div>

          {/* Quick Links Section */}
          <div className="lg:col-span-2 flex justify-center items-center lg:justify-start lg:mt-[-115px]">
            <div className="w-full max-w-md">
              <h1 className="text-white mb-4 text-lg text-center lg:text-left">Quick Links</h1>
              <ul className="flex flex-col items-center lg:items-start gap-2 text-[15px]">
                <li>
                  <Link href="/" className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    Home
                  </Link>
                </li>
                <li>
                  <Link href={pathname === '/' ? '#price_plan' : '/pricing'} className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link href="/blog" className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    Blog
                  </Link>
                </li>
                <li>
                  <Link href="/faq" className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link href="/privacy" className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link href="/contact" className="flex items-center text-white/90 hover:text-white hover:underline transition duration-200">
                    <MdOutlineArrowRight className="hidden md:block h-6 w-6 mr-2" />
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Newsletter Section */}
          <div className="lg:col-span-3">
            <h1 className="text-white mb-4 text-lg">Newsletter</h1>
            <p className="text-white/90">Sign up for our newsletter to stay updated with the latest news and blog posts.</p>
            <div className="relative w-full mt-2">
              <input
                name="signup"
                type="text"
                className="form-control p-2 border-0 text-gray-900 outline-none bg-gray-200 rounded-lg w-full py-3"
                placeholder="Your email"
              />
              <button
                type="button"
                className="bg-[#D5006D] h-full absolute -top-2 px-4 rounded-r-lg hover:bg-pink-600 right-0 mt-2 text-white"
              >
                Signup
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center text-white/70 py-3">
        <div className="text-sm">
          <p>
            &copy;
            {' '}
            {currentYear}
            {' '}
            <Link href="/" className="text-sky-600 hover:text-sky-500">CookGPT</Link>
            , All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
