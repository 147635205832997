/* eslint-disable react/prop-types */

'use client';

import { IoClose } from 'react-icons/io5';
import React, { useState } from 'react';
import Link from 'next/link';
import { features } from '@/lib/nav-menu-items';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useAuth } from '@clerk/nextjs';

const MobileNavSidebar = ({ mobileOpen, setMobileOpen }) => {
  const { isSignedIn } = useAuth();
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const navigate = useRouter();
  const handleLinkClick = (href) => {
    setMobileOpen(false);
    navigate.push(href);
  };

  return (
    <div className={`lg:hidden rounded-r-lg bg-deep-blue  font-lato font-medium text-gray-100 mobilenav ${mobileOpen ? 'nav-visible' : ''}`}>

      <div className="px-2 ml-1 flex justify-between items-center border-b py-2 border-red mb-12">
        <Link
          href="/"
          className="-m-4 p-1.5 flex gap-1 pt-[20px] font-semibold text-lg ml-1 flex items-center text-gray-100"
          onClick={() => handleLinkClick('/')}
        >
          <Image
            src="/cookgpt_logo_mobile.webp"
            alt="cookgpt_logo"
            className="h-[35px] min-w-[40px]"
            width={30}
            height={35}
          />
          cookgpt
        </Link>
        <IoClose onClick={() => setMobileOpen(false)} className="text-gray-100 text-4xl cursor-pointer" />

      </div>
      <ul className="px-8 space-y-4 pt-12">
        <li className="px-2 py-1">
          <div className="relative">
            <button
              type="button"
              onClick={() => setIsAboutUsOpen(!isAboutUsOpen)}
              className="flex items-center space-x-2 w-full text-left"
            >
              <span className="text-base">Features</span>
              <svg
                className={`w-5 h-5 transform transition-transform ${isAboutUsOpen ? 'rotate-180' : ''}`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isAboutUsOpen && (
              <ul className="pl-2 mt-2 space-y-2">
                {features.map((feature) => (
                  <li key={feature.id} className="px-1 py-1 rounded-lg ">
                    <Link
                          href={isSignedIn ? feature.href : '/sign-up'}
                          className="flex items-center gap-2 "
                      onClick={() => handleLinkClick(feature.href)}
                    >
                      <feature.icon className="text-xl" />
                      {feature.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
        <li>
          <Link
            href="/pricing"
            className="flex items-center space-x-2  px-2 py-1  rounded-lg"
            onClick={() => handleLinkClick('/media')}
          >
            Pricing
          </Link>
        </li>
        <li>
          <Link
            href="/blog"
            className="flex items-center space-x-2  px-2 py-1 rounded-lg"
            onClick={() => handleLinkClick('/all-activities')}
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            href="/faq"
            className="flex items-center space-x-2  px-2 py-1  rounded-lg"
            onClick={() => handleLinkClick('/membership')}
          >
            FAQ
          </Link>
        </li>
        <li>
          <Link
            href="/contact"
            className="flex items-center space-x-2  px-2 py-1  rounded-lg"
            onClick={() => handleLinkClick('/')}
          >
            Contact us
          </Link>
        </li>
      </ul>
      {' '}

    </div>
  );
};
export default MobileNavSidebar;
